.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999999;
}
@media screen and (max-width: 767px) {
  .spinner {
    left: 0 !important;
  }
}
.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  right: 0;
  bottom: 0;
  margin: auto;
}
.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ff9b44;
  border-color: #ff9b44 transparent #ff9b44 transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
      .spinner {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 40%;
        left: 35%;
      }
      .spinner:after {
        content: " ";
        display: block;
        width: 60px;
        height: 60px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid teal;
        border-color: teal transparent teal transparent;
        animation: spinner 1.2s linear infinite;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    } */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
      .spinner {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 35%;
        left: 56%;
      }
      .spinner:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid teal;
        border-color: teal transparent teal transparent;
        animation: spinner 1.2s linear infinite;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    } */
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
      .spinner {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 35%;
        left: 55%;
      }
      .spinner:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid teal;
        border-color: teal transparent teal transparent;
        animation: spinner 1.2s linear infinite;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    } */
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
      .spinner {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 30%;
        left: 58%;
      }
      .spinner:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid teal;
        border-color: teal transparent teal transparent;
        animation: spinner 1.2s linear infinite;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    } */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
      .spinner {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 30%;
        left: 55%;
      }
      .spinner:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid teal;
        border-color: teal transparent teal transparent;
        animation: spinner 1.2s linear infinite;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    } */
