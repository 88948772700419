@font-face {
    font-family: 'Sequel100Black-45';
    src: url('../Fonts/Sequel100Black-45.woff2') format('woff2'),
        url('../Fonts/Sequel100Black-45.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roc Grotesk';
    src: url('../Fonts/RocGrotesk-Regular.woff2') format('woff2'),
        url('../Fonts/RocGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sequel100Black-75';
    src: url('../Fonts/Sequel100Black-75.woff2') format('woff2'),
        url('../Fonts/Sequel100Black-75.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roc Grotesk';
    src: url('../Fonts/RocGrotesk-Light.woff2') format('woff2'),
        url('../Fonts/RocGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roc Grotesk';
    src: url('../Fonts/RocGrotesk-Medium.woff2') format('woff2'),
        url('../Fonts/RocGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roc Grotesk';
    src: url('../Fonts/RocGrotesk-Bold.woff2') format('woff2'),
        url('../Fonts/RocGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

