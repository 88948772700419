.right_wrap {
  padding: 90px 100px 65px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
.bg_image {
  background-image: url(../Images/bg_light_image.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.left_wrap {
  background-image: url(../Images/background_20.svg);
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: left;
  align-content: center;
  justify-content: center;
}
.equal {
  height: 100vh;
}
.carousel-indicators [data-bs-target] {
  display: flex;
  width: 18px;
  height: 16px;
  opacity: 1;
}
.carousel-indicators .active svg path {
  stroke: #f45545;
}
.slider_image {
  text-align: center;
  max-height: 450px;
  min-height: 450px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.slider_inner {
  text-align: center;
}
.carousel-indicators {
  bottom: -90px;
}
.slider_inner p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 25px;
  min-height: 50px;
}
.slider_image img {
  object-fit: contain;
}
.verify_image {
  background-image: url(../Images/verify_image.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.input_password {
  position: relative;
}
.input_password input {
  padding-right: 65px;
}
.input_password .toggle-password {
  position: absolute;
  right: 18px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.h-8 {
  height: 8px;
}
.step_text {
  position: absolute;
  right: 15px;
  bottom: 5px;
  color: #fff;
}
.min-290 {
  min-width: 290px;
}
.mb-30 {
  margin-bottom: 30px;
}
.top_box_shedo {
  box-shadow: 0px -20px 30px rgb(0 0 0 / 8%);
}
.left_under_line {
  position: relative;
}
.left_under_line:after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 30px;
  height: 1px;
  background-color: #f45545;
}
.w-105 {
  width: 105px;
  height: 105px;
}
.round-placeholder {
  background-color: #f45545;
}
.blue-placeholder {
  background: linear-gradient(224.06deg, #575757 0%, #2485f7 120.43%);
}
.skin-5 .num-in {
  max-width: 190px;
  width: 100%;
  /* min-height: 60px; */
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  border: 1px solid #eeeff1;
  /* padding: 10px; */
  padding: 7px;
  border-radius: 0;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 15%);
}
.skin-5 .no_select {
  width: auto;
  margin: 0 auto;
  line-height: normal;
}
.skin-5 .num-in span {
  /* font-size: 45px; */
  width: 20px;
  /* padding: 10px 0; */
  /* padding: 7px 0; */
  display: block;
}

.skin-5 .num-in span.minus {
  /* float: left;
  text-align: right; */
}

.skin-5 .num-in input {
  border: none;
  height: 41px;
  width: 128px;
  color: #f45545;
  float: left;
  text-align: center;
  background-color: transparent;
  font-size: 16px;
  font-size: 20px;
  line-height: 23px;
  font-weight: bold;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.skin-5 .num-in input:focus {
  /* outline: none;
  box-shadow: none; */
}
span.minus.dis {
  opacity: 0.5;
}
span.plus.dis {
  opacity: 0.5;
}
.skin-5 .num-in span.plus {
  /* float: right;
  text-align: left; */
}

.full_table tr th {
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  background-color: #fff;
  text-transform: uppercase;
  top: 0;
  z-index: 9;
  position: sticky;
}
.full_table tr th,
.full_table tr td {
  text-align: center;
}
.full_table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  padding: 20px 0;
}
.max-400 {
  max-width: 400px;
}
.table_m_scroll {
  height: calc(100vh - 435px);
  min-height: 260px;
  overflow: auto;
}
.logo_scroll {
  height: calc(100vh - 435px);
  min-height: 260px;
  overflow: auto;
  margin: auto;
  max-width: 1100px;
  padding-bottom: 10px;
}
.btn-group.btn-filter-group-check.max-400.d-table-cell div {
  display: inline-block;
}
.border_div {
  display: flex;
  flex-wrap: wrap;
  max-width: 1292px;
  margin: 0 auto;
}
.box_inner {
  width: 215px;
  display: flex;
  height: 215px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow:
    1px 0 0 0 #000,
    0 1px 0 0 #000,
    1px 1px 0 0 #000,
    1px 0 0 0 #000 inset,
    0 1px 0 0 #000 inset;
}
/* .border_div .box_inner:nth-child(6n) {
  border-right: 0;
} */
/* .border_div .box_inner:last-child,
.border_div .box_inner:nth-child(13n),
.border_div .box_inner:nth-child(14n),
.border_div .box_inner:nth-child(15n),
.border_div .box_inner:nth-child(16n),
.border_div .box_inner:nth-child(17n) {
  border-bottom: none;
} */

.active-grid {
  border: 5px solid #f45545 !important;
  box-shadow: none;
}
.box_inner .close_action {
  position: absolute;
  top: 5px;
  right: 5px;
}
.min-height {
  min-height: inherit !important;
}
.logo-295 {
  max-width: 295px;
}
.avatar-64 {
  border: 5px solid #f45545;
  border-radius: 100%;
  width: 60px;
  height: 60px;
}

.left_menu {
  position: fixed;
  width: 380px;
  height: 100%;
  top: 0;
  z-index: 9;
  background-color: #000;
  left: -380px;
  transition: 0.5s ease;
}
.left_menu.open {
  left: 0;
  transition: 0.5s ease;
  overflow: auto;
}
.left_menu_side li a {
  color: #fff;
  font-size: 22px;
  width: 100%;
  display: flex;
  align-content: center;
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 20px;
  transition: 0.5s ease;
}
.left_menu_side li a:hover {
  background-color: rgb(244 85 69 / 30%);
  transition: 0.5s ease;
}
.left_menu_side li a:after {
  content: " ";
  position: absolute;
  width: 90%;
  bottom: 0;
  height: 1px;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: auto;
}
.icon-md {
  width: 30px;
  height: auto;
  margin-right: 15px;
  display: inline-block;
}
.v-6 {
  vertical-align: -6px;
}
.v-4 {
  vertical-align: -4px;
}
.v-5 {
  vertical-align: -5px;
}
.v-1 {
  vertical-align: -1px;
}
.v-2 {
  vertical-align: -2px;
}
.v-13 {
  vertical-align: -13px;
}
.bg_color_light_blue {
  background-color: #55b0f2 !important;
}
.bg_color_light_red {
  background-color: #f45545 !important;
}
.bg_color_light_green {
  background-color: #85bb65 !important;
}
.badge-31 {
  width: 31px;
  height: 31px;
  text-align: center;
  font-size: 14px;
  line-height: 31px;
  margin-top: auto;
  margin-bottom: auto;
}
.icon_search {
  max-width: 300px;
}
.form-control.border-input {
  border-radius: 0;
  border-color: #000000;
  min-height: 50px;
  max-width: 350px;
  font-size: 18px;
  line-height: 23px;
  font-size: 20px;
}
.form-control.border-input:focus {
  box-shadow: none;
  outline: none;
}
.icon_search {
  width: 320px;
  max-width: 350px;
}
.icon_search .search_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  margin: auto;
  bottom: 0;
  /* background-color: transparent; */
  /* border: 0; */
}
.icon_search .cancel_search_icon {
  position: absolute;
  top: 0;
  right: 45px;
  margin: auto;
  bottom: 3px;
  height: 20px;
  width: 20px;
}
.icon_search .before_text {
  position: absolute;
  top: 0;
  left: 15px;
  margin: auto;
  bottom: 3px;
  height: 20px;
  width: 20px;
}
.icon_search .form-control.border-input {
  /* padding-left: 10px; */
  padding-right: 70px;
  overflow: scroll;
}
.icon_md {
  width: 50px;
  height: auto;
  display: inline-block;
}
.card.box-shedo-card {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border: 0;
  /* min-height: 275px; */
  border-radius: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.card.box-shedo-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.product_item {
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 100%;
  font-size: 12px;
  color: #fff;
  line-height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.product_item.left_set {
  left: 10px;
}
.product_del_price:after {
  content: " ";
  position: absolute;
  min-height: 44px;
  top: 2px;
  min-width: 55px;
  background-image: url(../Images/del-price-line.png);
  left: -5px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.product_price {
  min-width: 77px;
}
.product_price:after {
  content: " ";
  position: absolute;
  min-height: 44px;
  top: -10px;
  min-width: 77px;
  background-image: url(../Images/product_price.svg);
  left: 0;
  background-repeat: no-repeat;
  background-size: 100%;
}
a.black_tab {
  color: #000;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.body_section {
  height: calc(100% - 200px);
  overflow: auto;
}
header {
  background-image: url(../Images/header_bg.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.banner_card .white_like {
  position: absolute;
  bottom: 15px;
}
.fixed_header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #f2f2f2;
  padding-bottom: 0 !important;
}
.fixed_header .header_middle {
  position: unset !important;
}
.body_section {
  margin-top: 0 !important;
  padding-top: 20px;
  background-color: #f2f2f2;
}
.body_section1 {
  margin-top: 90px !important;
  padding-top: 20px;
}
/*.grid {
	display: flex;
	flex-wrap: wrap;
}
.grid_items {
    width: 25%;
    padding: 0 10px;
}*/
.right_filter {
  position: fixed;
  right: -750px;
  top: 0;
  width: 600px;
  z-index: 9;
  transition: 0.5s ease;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right_filter.open {
  right: 0;
  transition: 0.5s ease;
}
.sku_filter_div {
  position: fixed;
  right: -700px;
  top: 0;
  width: 600px;
  overflow: auto;
  z-index: 9;
  transition: 0.5s ease;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.sku_filter_div.open {
  right: 0;
  transition: 0.5s ease;
}
body.open_filter:before {
  content: "";
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
  position: fixed;
  height: 100%;
  width: 100%;
}
body.open_filter {
  position: relative;
  overflow: hidden;
}
.chips {
  width: auto !important;
  border: 0;
  padding: 10px 15px;
  box-shadow: none;
  font-size: 13px;
  min-width: 110px;
  /* background: #f45545; */
  border-radius: 50px;
  line-height: 42px;
  border-radius: 20px;
  max-height: 65px;
  min-height: 65px;
  color: #fff;
  position: relative;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  text-align: center;
  min-width: 170px;
}
.chips:before {
  /* content: " ";
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  line-height: 100%;
  min-height: 53px;
  background-image: url(../Images/bg_chips.svg);
  background-repeat: no-repeat;
  background-size: 100%; */
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: 0px;
  line-height: 100%;
  min-height: 70px;
  background-image: url(../Images/round_fill.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.grid2 {
  grid-column: 3;
  grid-row: 2/4;
  padding: 0 10px;
  margin-bottom: 15px;
}
.grid1 {
  grid-column: 1;
  grid-row: 4/6;
  padding: 0 10px;
  margin-bottom: 15px;
}
.grid {
  padding: 0 10px;
  margin-bottom: 15px;
}
.border_accordion .accordion-button::after {
  display: none;
}
img.product_image {
  max-height: 140px;
  /* max-width: 280px; */
  max-width: 100%;
}
.product_image {
}
.grid2 .card.box-shedo-card .card-body,
.grid1 .card.box-shedo-card .card-body {
  min-height: 575px;
  background-position: center;
}
.grid2 .card.box-shedo-card .card-body {
  background-size: cover;
  background-repeat: no-repeat;
}
.grid1 .card.box-shedo-card .card-body {
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
}
.div_button .btn-group label.btn.btn-outline-primary {
  width: auto;
  box-shadow: none;
  text-transform: uppercase;
  margin: 0 !important;
  margin-right: 10px !important;
}
.div_button .btn-group input:checked + label.btn.btn-outline-primary {
  color: #fff !important;
  background-color: #f45545 !important;
  border-color: #f45545 !important;
}
.range {
  border: 1px solid #000;
}
.range select {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 50px;
  width: 65px;
  text-align: center;
  font-size: 18px;
  padding: 5px;
}
.range select:focus {
  outline: none;
}
.divider {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 0 8px;
  display: flex;
}
.form-control.border_select:focus {
  outline: none;
  box-shadow: none;
}
.form-control.border_select {
  border-color: #000;
  line-height: 40px;
  border-radius: 0;
  min-height: 50px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 18px;
  background-image: url(../Images/down_arrow.svg);
  background-position-x: 95%;
  background-repeat: no-repeat;
  /* background-position-y: 14px; */
  background-position-y: 10px;
}
.icon-48 {
  width: 35px;
  display: inline-block;
  height: 35px;
}
.font-seque-bold {
  font-family: Sequel100Black-75;
  letter-spacing: 2px;
}
.letter_space_0 {
  letter-spacing: normal;
}
.filter_button .btn-group label.btn.btn-outline-primary {
  min-width: 140px;
  padding: 10px 15px;
}
.filter_button.div_button .btn-group label.btn.btn-outline-primary {
  margin-right: 0 !important;
}
.filter_button.div_button
  .btn-group
  input:checked
  + label.btn.btn-outline-primary {
  border-color: #000000 !important;
}
.filter_button.div_button .btn-group label.btn.btn-outline-primary {
  font-size: 18px;
}
.body_wrap header.large_header + .body_section {
  /* margin-top: 255px !important; */
  padding-top: 20px;
}
.body_wrap header.small_header + .body_section {
  /* margin-top: 160px !important; */
  padding-top: 20px;
}
.body_wrap header.small_header {
  background-image: none;
}
.body_wrap header.header_no_bg + .body_section {
  /* margin-top: 110px !important; */
  padding-top: 20px;
}
.icon-50 {
  width: 40px;
  height: 40px;
  display: inline-block;
}
.guaranteed {
  position: absolute;
  left: 15px;
}
.product-icon {
  /* position: absolute; */
  /* right: 33%; */
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 6px;
  color: #f14436;
  background: rgba(255, 101, 80, 0.15);
  box-shadow: 2px 1px 3px 1px rgb(113 38 28 / 15%);
}
.icon-15 {
  width: 15px;
  height: 15px;
  display: inline-block;
}
.icon-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.icon-30 {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.icon-10 {
  width: 14px;
  height: 14px;
  display: inline-flex;
}
.sku_filter.div_button .btn-group label.btn.btn-outline-primary {
  align-content: center !important;
  justify-content: center;
  font-size: 16px;
  line-height: 31px;
  padding: 10px 15px;
  display: flex;
}
.sku_filter.div_button .btn-group label.btn.btn-outline-primary img {
  margin-right: 5px;
}
.div_button.sku_filter
  .btn-group
  input:checked
  + label.btn.btn-outline-primary
  .icon_defult {
  display: none;
}
.div_button.sku_filter
  .btn-group
  input:checked
  + label.btn.btn-outline-primary
  .icon_active {
  display: inline-block;
}

.div_button.sku_filter
  .btn-group
  input
  + label.btn.btn-outline-primary
  .icon_defult {
  display: inline-block;
}
.div_button.sku_filter
  .btn-group
  input
  + label.btn.btn-outline-primary
  .icon_active {
  display: none;
}
header.header_no_bg {
  background-image: none;
}
.product_like_count {
  position: absolute;
  right: 10px;
  top: 10px;
  min-width: 60px;
  min-height: 20px;
  background-image: url(../Images/like_bg.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.color_light {
  color: #9c9c9c;
}
.bag_icon {
  background-image: url(../Images/bag_icon.svg);
  background-position: center;
  width: 30px;
  height: 30px;
  line-height: 39px;
  display: inline-block;
  background-repeat: no-repeat;
  font-size: 15px;
}
.min-inherit {
  min-height: inherit !important;
}
.price_change {
  font-size: 20px;
  line-height: 20px;
  color: #85bb65;
  font-weight: bold;
}
.v_1 {
  vertical-align: 1px;
}
.product_offter {
  position: absolute;
  left: 10px;
  top: 10px;
}
.min_120 {
  /* min-width: 89px; */
  display: inline-block;
}
.min_115 {
  min-width: 115px;
  display: inline-block;
}
.w-80 {
  width: 80px;
  height: 80px;
  border: 5px solid #f45545;
}
.product_price {
  min-width: 77px;
  display: inline-block;
}
.border_accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #000;
}
.border_accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.border_accordion
  .accordion-item:last-of-type
  .accordion-button.collapsed:focus {
  outline: none;
  box-shadow: none;
}
.border_accordion .accordion-item:last-of-type .accordion-collapse {
  border-top: 0;
}
.border_accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.border_accordion .accordion-item:last-of-type {
  border-radius: 0;
  background: #ffffff;
  border: 0;
  margin-bottom: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
/* .min_140 {
  min-width: 110px;
  display: inline-block;
} */
.font-20 {
  font-size: 20px;
}
.light_box {
  background-color: #ececec;
  color: #9c9c9c;
  padding: 8px 18px;
}
.btn-link.btn-link-primary {
  color: #f45545;
  text-decoration: none;
  font-size: 20px;
}
.max-w-200 {
  max-width: 150px;
  width: 100%;
}
.green_text {
  width: 22px;
  height: 22px;
  background-color: #85bb65;
  display: inline-block;
  border-radius: 100%;
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.avatar-64.light_border {
  border-color: #dedede !important;
  outline: 3px solid #fff;
}
.change_product {
  margin: auto;
  height: initial;
  position: absolute;
  top: 17px;
  left: -36px;
  right: auto;
  background: #dedede;
  display: inline-block;
  padding: 4px 15px;
  font-size: 15px;
  border-radius: 125px;
  z-index: -1;
  padding-right: 23px;
}

.min-filter-50 {
  min-width: 50px;
}
.min-filter-80 {
  min-width: 80px;
}
.image-gallery-slide.center {
  background-color: #ffffff !important;
}

.image-gallery-slides {
  max-height: 920px;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27><path d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/></svg>");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.product_name {
  max-width: 650px;
}
.fixed-footer {
  position: fixed !important;
  width: 100% !important;
  bottom: 0 !important;
}
button.image-gallery-icon.image-gallery-right-nav svg,
button.image-gallery-icon.image-gallery-left-nav svg {
  width: 30px;
  height: auto;
}
button.image-gallery-icon.image-gallery-right-nav,
button.image-gallery-icon.image-gallery-left-nav {
  z-index: 1 !important;
  padding: 0;
}
.image-gallery-icon:hover {
  color: #f45545 !important;
}
.image-gallery-icon {
  filter: none !important;
  color: gray !important;
}
.image-gallery-icon:hover .image-gallery-svg {
  transform: none !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border-color: #f45545 !important;
}
.image-gallery-thumbnail:hover {
  border-color: #f45545 !important;
}

.min-h-50 {
  min-height: 50px;
}
.skin-5 .no_select option {
  text-align: center;
}
.min-h-300 {
  min-height: 300px;
}
.image-gallery-content.fullscreen
  .image-gallery-slide
  .image-gallery-description {
  bottom: 15% !important;
}
.fa {
  margin-right: 1rem !important;
}
.notification-bg-color {
  background: rgb(88 156 43);
  font-size: 1.25rem;
  margin-bottom: 10px !important;
}
.select-sort {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  width: 70px;
  border-radius: 50px;
  padding: 7px 12px 4px;
  color: #000;
  font-size: 12px;
  border: 1px solid #020202 !important;
  box-shadow: 0 0 0 1px #020202 !important;
  outline: 0;
}
.selectbox__option--is-focused {
  background-color: #e56e62 !important;
  color: white !important;
  cursor: pointer !important;
}
.selectbox__option--is-selected {
  background-color: #f45545 !important;
}
.selectbox__control {
  border-color: #020202 !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  overflow: hidden;
  cursor: pointer !important;
}
.selectbox__control--is-focused:hover,
.selectbox__control--menu-is-open:hover {
  border-color: #020202 !important;
  box-shadow: 0 0 0 1px #020202 !important;
}
.selectbox__control--is-focused,
.selectbox__control--menu-is-open {
  border-color: #020202 !important;
  box-shadow: 0 0 0 1px #020202 !important;
}
.selectbox__control .selectbox__value-container {
  padding: 4px 15px;
  min-height: 47px;
}
.selectbox__control .selectbox__value-container--has-value,
.selectbox__control .css-1s2u09g-control {
  background: #f45545 !important;
}
.css-1pahdxg-control {
}
.selectbox__control
  .selectbox__value-container--has-value
  .selectbox__placeholder,
.selectbox__control
  .selectbox__value-container--has-value
  .selectbox__single-value,
.selectbox__control
  .selectbox__value-container--has-value
  .selectbox__input-container {
  color: #fff;
}
.selectbox__control .selectbox__placeholder,
.selectbox__control .selectbox__input-container {
  color: #000;
}
.selectbox__control .selectbox__indicators {
  display: none;
}
.selectbox__menu {
  min-width: 150px;
}

.bulk-deals-sort-select .selectbox__menu {
  right: 0;
  min-width: 150px;
}

.bulk_deals_product_content {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;
}

.header-bottom-icons-content {
  margin-right: 1rem;
}

.header-bottom-icons-content .header-bottom-icons-img {
  height: 70px;
  width: 70px;
}

.header-middle-promo-card {
  width: 50%;
  margin: 0 auto;
}

.header-promo-card-content {
  min-height: 230px !important;
}

.header-promo-card-content .header-promo-image-text-content {
  display: flex;
  align-items: center;
  margin-top: 0px;
  gap: 2rem;
}

.bulk-deals-product-text .product_like {
  min-width: 110px;
  text-align: left;
}

.back-to-top-icon-btn {
  border: 1px solid #000;
  padding: 6px 0.8rem;
  border-radius: 10px;
}
.header_bottom.header-bottom-active {
  position: sticky;
  top: 148px;
  z-index: 1;
  background: #f2f2f2;
  transition: top 0.3s ease;
  width: 100%;
}

.header_bottom.fixed_header_bottom {
  top: 98px !important;
}

.bulk_deals_products {
  width: 100%;
  background: #fff;
  border-radius: 0.3rem;
  padding: 0.4rem;
}

.bulk_deals_products .bulk-deals-description-text {
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
