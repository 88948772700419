.link-list li a {
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  letter-spacing: 1px;
}
.link-list li {
  display: inline;
  margin: 0 15px;
}
.body_wrap header.small_header + .body_section {
  /* margin-top: 150px !important; */
}
.header_bg {
  background-image: url(../Images/header_bg.svg) !important;
}
.nabTabs li.nav-item {
  width: 33.3%;
}
.nabTabs li.nav-item .nav-link {
  width: 100%;
  font-size: 24px;
  line-height: 22px;
  text-transform: uppercase;
  color: #00000033;
  border-color: #00000033;
  padding: 15px 16px;
}
.nabTabs li.nav-item:hover .nav-link {
  color: #f45545;
  font-weight: 600;
  border-color: #f45545;
}
.nabTabs li.nav-item .nav-link.active {
  color: #f45545;
  font-weight: 600;
  border-color: #f45545;
}
.tabDesc {
  font-size: 20px;
  line-height: 37px;
}
.tabDesc p {
  margin-bottom: 1rem;
}
.btn.min-h-50 {
  min-height: 50px;
}
.btn.btn_small {
  padding: 15px 40px;
  font-size: 16px;
  min-height: 50px;
  transition: 0.5s ease;
}
.btn.btn_small:hover {
  background-color: #ffffff;
  color: #f45545;
  transition: 0.5s ease;
}
.primary_dots li {
  position: relative;
  padding-left: 30px;
}
.primary_dots li:before {
  content: " ";
  border-radius: 100%;
  position: absolute;
  top: -5px;
  bottom: 0;
  margin: auto;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #f45545;
}
.dark-modal .modal-content {
  background: url(../Images/popup_bg.png), #000000;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: left;
  border-radius: 0;
}
.dark-modal .close_button {
  top: 50px;
  right: 30px;
}
.close_button {
  position: absolute;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  background-color: #f45545;
  font-size: 11px;
  text-align: center;
  opacity: 1;
  color: #ffffff !important;
  border-radius: 100%;
}
.dark-modal .modal-lg {
  max-width: 600px;
}
.font-14 {
  font-size: 14px;
  letter-spacing: 1px;
}
.primary_color {
  color: #f33604;
}
.max-200 {
  max-width: 200px;
}
@media screen and (max-width: 991px) {
  .link-list li a {
    font-size: 18px;
    line-height: 23px;
  }
  body .body_wrap header.small_header + .body_section {
    /* margin-top: 130px !important; */
  }
}
@media screen and (max-width: 767px) {
  body .body_wrap header.small_header.header_bg + .body_section {
    margin-top: 90px !important;
  }
  .nabTabs li.nav-item .nav-link {
    font-size: 14px;
    padding: 10px 5px;
  }
  .link-list li a {
    font-size: 14px;
    line-height: 19px;
  }
  .tabDesc {
    font-size: 16px;
    line-height: 29px;
  }
  .primary_dots li:before {
    top: 7px;
    width: 10px;
    height: 10px;
    margin: 0;
  }
  .link-list li {
    margin: 0 3px;
  }
  .sidebar img {
    max-width: 20px;
  }
  .logo-295 {
    max-width: 125px;
  }
  .btn.btn_small {
    padding: 10px 30px;
    font-size: 16px;
    min-height: 30px;
  }
  .dark-modal .close_button {
    top: 15px;
    right: 15px;
  }
}

footer {
  background-color: #000000 !important;
}
.inline_links li {
  display: inline-block;
  padding: 0 10px;
}
.sm-icon img {
  max-width: 90px;
}

.footer_bottom_links li {
  display: inline-block;
  padding: 0 10px;
  position: relative;
}
.footer_bottom_links li:after {
  content: " ";
  position: absolute;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ffffff;
}
.footer_bottom_links li:last-child::after {
  display: none;
}
.footer_bottom_links {
  margin-bottom: 10px;
}
.footer_bottom_links li a {
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}
.inline_links li:first-child {
  padding-left: 0;
}
@media screen and (max-width: 767px) {
  .footer_bottom_links li a {
    font-size: 14px;
  }
  .footer_bottom_links li {
    margin-bottom: 10px;
  }
}
.dark-modal.full_width .modal-content {
  background-image: none;
}
.full_width .close_button {
  background-color: #000000;
  top: 17px;
  right: 10px;
  font-size: 16px;
}
@media (max-width: 380px) {
  .link-list li a {
    font-size: 12px;
  }
}
.seekrz-store {
  color: #ffffff;
  position: relative;
  align-items: center;
}
.seekrz-store p {
  font-size: 18px;
  line-height: 1 !important;
  margin-top: 3%;
}
.seekrz-store .special-label {
  display: none;
}
.body_section {
  padding-bottom: 105px;
}
.footer-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.footer_bottom_links_wrap {
  display: inline-flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 991.99px) {
  .footer_bottom_links_wrap {
    padding-left: 0 !important;
  }
}
.seekrz-store {
  min-height: 100vh !important;
}
.alert.seekrz-alert ~ .seekrz-store {
  min-height: calc(100vh - 84px) !important;
}
body,
html,
#root,
.App,
.body_wrap {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.body_section {
  flex-grow: 1;
}
@media (max-width: 767.99px) {
  .footer-bottom {
    position: relative;
  }
}
.seekrz-store-signup {
  font-family: Sequel100Black-75;
  padding: 3rem;
  margin: 3rem 0 5rem;
  background: #f45545;
  /* text-transform: uppercase; */
  color: #fff;
}

.seekrz-store-signup label {
  color: #fff;
}

.seekrz-store-signup .btn-container {
  display: flex;
  justify-content: center;
}

.seekrz-store-signup button {
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0.8rem 1.3rem;
  margin-top: 1.5rem;
  background: transparent;
  border: 4px solid #fff;
  border-radius: 4px;
  transition: transform 0.2s;
}
.checkbox-block input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  filter: invert(1);
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  position: relative;
  top: 4px;
}
.checkbox-block input[type="checkbox"]:checked:before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("../Images/check-bold.png") no-repeat center;
  display: block;
  position: absolute;
  background-size: 18px;
  right: -7px;
  z-index: 5;
  top: -6px;
  transform: rotate(10deg);
}

@media screen and (max-width: 767.98px) {
  .seekrz-store-signup {
    padding: 15px;
  }
  .seekrz-store h5,
  .seekrz-store p,
  .seekrz-store-signup .form-label {
    font-size: 16px;
  }
}
