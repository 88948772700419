img {
  width: auto;
  height: auto;
}
.no_select {
  font-size: 20px;
  line-height: 23px;
  width: 50%;
  color: #f45545;
  text-align: center;
  min-height: 40px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  font-weight: bold;
  background-color: #f2f2f2;
}
.no_select:focus {
  outline: none;
  border: none;
}
.green_font {
  font-weight: bold;
  color: #85bb65;
  font-size: 20px;
  line-height: 23px;
}
.click_action {
  position: absolute;
  bottom: 10px;
}
.btn-outline-primary {
  background-color: #fff;
}
.colorred {
  color: #f45545;
}
.cp {
  cursor: pointer;
}
.sortbtncheck {
  color: #fff !important;
  background-color: #f45545 !important;
  border-color: #f45545 !important;
}
.click_action button {
  margin: 0 5px;
  max-height: 38px;
}
.css-1rhbuit-multiValue {
  display: none !important;
}
.select__clear-indicator {
  display: none !important;
}
.select__indicator-separator {
  display: none !important;
}
.select__dropdown-indicator {
  display: none !important;
}
.select__menu {
  z-index: 99 !important;
}
.btn-group .btn.btn-outline-primary.active_filter {
  background-color: #f45545 !important;
  color: #fff !important;
}
.slider_text {
  position: absolute;
  top: calc(100% - 310px);
  background-color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  left: 30px;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
}

.select__control {
  border-radius: 0 !important;
  border-color: #000000 !important;
  min-height: 60px !important;
  max-width: 350px !important;
  font-size: 18px !important;
  line-height: 23px !important;
  font-size: 20px !important;
  padding-left: 10px !important;
}
.avatar_image {
  width: 100%;
  position: relative;
  top: 0%;
  left: 2%;
  border-radius: 50%;
}
.react-tel-input .country-list {
  padding: 5px !important;
}
.form-control.black-border {
  min-height: 50px !important;
}
.number_verify {
  min-height: 40px !important;
}
.btn-primary.btn-primary-red {
  min-height: 50px !important;
}
.btn-group label.btn.btn-outline-primary {
  padding: 9px 14px;
}
.sortarrow {
  width: 20px;
}
.height-50 {
  height: 50px !important;
}
/* .fullscreen img.image-gallery-image {
  min-height: 565px !important;
} */

img.image-gallery-image {
  max-height: calc(100vh - 110px) !important;
  min-height: 400px;
}

/* dashboard condition  */
.SlideValue .dashmin {
  /* margin-top: -15px; */
  position: absolute;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.SlideValue .dashmax {
  position: absolute;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.SlideValue .rc-slider-handle-1 {
  margin-top: -15px;
}
.SlideValue .rc-slider-handle-2 {
  margin-top: -15px;
}
.SlideValue .rc-slider-rail {
  height: 2px;
  background-color: #000000;
}
.SlideValue .rc-slider-track {
  background-color: #f45545;
}
.SlideValue .rc-slider-handle {
  width: 33px;
  height: 33px;
  opacity: 1;
  border-color: #000000 !important;
}
.SlideValue
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #000000;
  box-shadow: none;
}

/* home price  */
.home_price .rc-slider-handle-1 {
  margin-top: -15px;
}
.home_price .minpricelabel {
  position: relative;
  left: -2%;
}
.home_price .maxpricelabel {
  position: relative;
  left: 92%;
}
.home_price .rc-slider-handle-1:after {
  content: attr(aria-valuenow);
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
}
.home_price .rc-slider-handle-2 {
  margin-top: -15px;
}
.home_price .rc-slider-handle-2:after {
  content: attr(aria-valuenow);
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
}
.home_price .rc-slider-rail {
  height: 2px;
  background-color: #000000;
}
.home_price .rc-slider-track {
  background-color: #f45545;
}
.home_price .rc-slider-handle {
  width: 33px;
  height: 33px;
  opacity: 1;
  border-color: #000000 !important;
}
.home_price
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #000000;
  box-shadow: none;
}
/* end  */

/* sku listing price  */
.SkuPrice .rc-slider-handle-1 {
  margin-top: -15px;
}
.SkuPrice .minpricelabel {
  position: relative;
  /* top: 24%; */
  left: -2%;
}
.SkuPrice .maxpricelabel {
  position: relative;
  /* top: 24%; */
  left: 92%;
}
.SkuPrice .rc-slider-handle-1:after {
  content: "$" attr(aria-valuenow);
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
}
.SkuPrice .rc-slider-handle-2 {
  margin-top: -15px;
}
.SkuPrice .rc-slider-handle-2:after {
  content: "$" attr(aria-valuenow);
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
}
.SkuPrice .rc-slider-rail {
  height: 2px;
  background-color: #000000;
}
.SkuPrice .rc-slider-track {
  background-color: #f45545;
}
.SkuPrice .rc-slider-handle {
  width: 33px;
  height: 33px;
  opacity: 1;
  border-color: #000000 !important;
}
.SkuPrice
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #000000;
  box-shadow: none;
}

/* sku listing condition  */
.SkuCondition .skumin {
  /* margin-top: -15px; */
  position: absolute;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.SkuCondition .skumax {
  position: absolute;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.SkuCondition .rc-slider-rail {
  height: 2px;
  background-color: #000000;
}
.SkuCondition .rc-slider-track {
  background-color: #f45545;
}
.SkuCondition .rc-slider-handle {
  width: 33px;
  height: 33px;
  opacity: 1;
  border-color: #000000 !important;
}
.SkuCondition
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #000000;
  box-shadow: none;
}

input.search-box {
  width: -webkit-fill-available;
  border-radius: 0 !important;
  line-height: 25px !important;
  margin-left: 0 !important;
}
li.search {
  padding: 0 !important;
}
.outerdiv_image {
  min-width: 250px;
  display: inline-block;
  min-height: 175px;
  max-height: 163px;
  max-height: 175px;
  max-width: 280px;
}
.height-45 {
  /* min-height: 45px; */
}
.full_width .close_button {
  background-color: #000000;
  top: 17px;
  right: 10px;
  font-size: 16px;
}
.close_button {
  position: absolute;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  background-color: #f45545;
  font-size: 11px;
  text-align: center;
  opacity: 1;
  color: #ffffff !important;
  border-radius: 100%;
}

.image-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-fullscreen .image-modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
input.cookie {
  width: 20px;
  height: 20px;
}
.image-gallery-slide .image-gallery-description {
  left: 23px !important;
  border-radius: 4px;
  padding: 10px 10px !important;
  background-color: #f2f2f2 !important;
  color: black !important;
  bottom: 20px !important;
  cursor: default;
}
.image-logo {
  max-width: 160px;
  max-height: 180px;
}
.btn-primary-logo {
  background-color: #f45545;
  border-color: #f45545;
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  border-radius: 8px;
  text-transform: uppercase;
}
.click_action .btn-primary.btn-primary-red {
  min-height: 38px !important;
}
.img-width-60 {
  max-width: 60%;
  max-height: 80%;
}
.no_style_div div {
  margin: 0px;
}
/* contact us form  */
.ContactForm {
  font-family: Sequel100Black-75;
  padding: 3rem;
  margin: 3rem 0 5rem;
  background: #f45545;
  text-transform: uppercase;
  color: #fff;
}
.ContactForm input {
  font-size: inherit;
  color: inherit;
  border: none;
  font-family: Arial;
  font-size: 22px;
}

.ContactForm textarea:focus,
input:focus {
  outline: none;
}

.ContactForm__title {
  font-size: 1.5rem;
  text-decoration: underline;
  margin-bottom: 0.8rem;
}
.ContactForm__text {
  font-family: Roc Grotesk;
  font-size: 1.5rem;
  margin-bottom: 5rem;
}
.ContactForm .error {
  margin-bottom: 2rem;
}
.ContactForm__input-group {
  display: flex;
  border-bottom: 1px solid #fff;
}
.ContactForm__input,
.ContactForm__label,
.ContactForm__message-box {
  font-size: 1.5rem;
}
.ContactForm__input-container {
  flex: 1 1;
}
.ContactForm__input {
  padding-left: 1rem;
  width: 100%;
  background: transparent;
}
.ContactForm__input,
.ContactForm__label,
.ContactForm__message-box {
  font-size: 1.5rem;
}
.ContactForm__btn-container {
  display: flex;
  justify-content: flex-end;
}
.ContactForm__message-box {
  font-family: sans-serif;
  width: 100%;
  height: 15rem;
  padding: 2rem;
  color: #000;
}
.ContactForm__submit-btn {
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0.8rem 1.3rem;
  margin-top: 1.5rem;
  background: transparent;
  border: 4px solid #fff;
  border-radius: 4px;
  transition: transform 0.2s;
}

/* faq  */

.faq-card .card-header {
  background-color: #fff;
  padding: 20px;
  position: relative;
}
.faq-card .card {
  border-color: #ddd;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: hidden;
}
.faq-card .card .card-header h4 {
  margin-bottom: 0;
}
.faq-card .card .card-header h4 > a {
  color: #090909;
  display: block;
  font-size: 20px;
  font-weight: 400;
  padding-right: 60px;
  position: relative;
}
.faq-card .card .card-header h4 > a:after {
  font-family: FontAwesome;
  font-size: 19px;
  position: absolute;
  right: 0px;
  font-weight: 400;
}
.faq-card .card .card-header h4 > label {
  color: #fff;
  display: flex;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding-right: 60px;
  position: relative;
}
.faq-card .card .card-header h4 > label:after {
  font-family: FontAwesome;
  font-size: 19px;
  position: absolute;
  right: 0px;
  font-weight: 400;
  top: 0;
}
.faq-card .card .card-header h4 > label.collapsed:after {
  content: "\f067";
}
.faq-card .card .card-header h4 > label:not(.collapsed):after {
  content: "\f068";
}
.small-container {
  margin: auto;
  max-width: 1100px;
  padding: 30px;
}
.inner-header {
  margin-bottom: 30px;
}
.tag {
  color: white !important;
}
.tag:hover {
  color: #f45545 !important;
}
.product_card_bottom {
  /* position: absolute;
  width: 100% !important;
  bottom: 20px;
  padding: 0px 10px;
  z-index: 0; */
  margin-top: auto !important;
}
.placeholder_image {
  min-height: 140px;
  max-height: 140px;
  margin-top: 15px;
  /* max-width: 280px; */
  /* max-width: 100%; */
}
.image-gallery-fullscreen-button .image-gallery-svg {
  color: gray;
}
/* .modal-backdrop {
  z-index: -1;
  opacity: 0 !important;
} */
.logo_center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}
.react-tel-input .form-control:focus {
  box-shadow: 0px 10px 10px rgb(0 0 0 / 15%) !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: 0px 10px 10px rgb(0 0 0 / 15%) !important;
  border-color: unset !important;
  top: unset;
  border: unset;
}
.calc_center {
  height: calc(100vh - 185px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home_calc_center {
  height: calc(100vh - 442px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* blog style  */
.blog_main_div {
  background-color: #ffffff !important;
  margin: 0 15%;
  padding: 1%;
}
.blog_main_div h5,
p {
  line-height: 3 !important;
}
.blog_main_div p {
  font-size: 18px;
}
.image-gallery-icon {
  z-index: 0 !important;
}
.gray {
  background-color: #f2f2f2;
}
.btn-login {
  min-height: 50px;
  background-color: #f45545;
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  border-radius: 8px;
  border: none;
}
.image-gallery-thumbnails {
  height: 10%;
}
.view-all {
  border-radius: 25px;
  font-size: 18px;
  background-color: transparent;
  padding: 8px 20px;
  border-color: #000000;
  color: #000000;
}
.line-height-2 {
  line-height: 2 !important;
}
.google_btn {
  color: white !important;
  border-radius: 12px !important;
  display: flex !important;
  justify-content: center !important;
  padding: 5px 35px !important;
}
.btn-group label.btn.btn-outline-primary.new_round_btn {
  border: 0;
  background-color: #e7e7e7 !important;
  border-radius: 8px;
}
.font-12 {
  font-size: 12px;
}
.store-icon {
  object-fit: contain;
}
.store_icon_outer {
  width: 100px;
  height: 100px;
  background-color: #fff;
}
.store_icon {
  width: auto;
  height: auto;
  max-width: 100%;
}
.signupdealbox {
  padding: 15px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  margin-bottom: 5px;
}
.disabled-link {
  opacity: 0.65;
}
.seekrz-alert {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
}
.seekrz-alert.show ~ .body_wrap .header-wrap {
  top: 54px;
}
.close-white {
  background-image: url("../Images/close_white.svg") !important;
  font-size: 23px;
}
.close-white {
  opacity: 1;
}
.fadess {
  transition: opacity 1s linear !important;
}
.footer-logo img {
  display: block;
  max-width: 190px;
}
.footer-bottom {
  background: black;
}
.logo-img {
  width: 36px;
  flex-shrink: 0;
}
.seekrz-alert {
  padding-top: 8px;
  padding-bottom: 8px;
}
.seekrz-alert .open-btn {
  padding: 3px 0.75rem;
}
.close-white {
  width: 17px;
  height: 17px;
  background-size: 19px;
}
.flag-icon {
  width: 40px;
}
:root {
  --slideWidth: 250px;
}

.blog-sidebar {
  width: 320px;
}
.blog-mainwrap {
  gap: 40px;
}
.blog-navlinks {
  padding: 0;
}
.blog-navlinks a {
  font-weight: 400;
  display: block;
}
.blog-navlinks li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.blog-navlinks li:last-child {
  border-bottom: 0;
}
.blog-title {
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-bottom: 0;
}

.blogtitle-wrap {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
}
.blogtitle-wrap span {
  color: #909090;
  font-size: 12px;
}
.blog-post:nth-child(even) .row {
  flex-direction: row-reverse;
}
.blog-post:not(:last-child) {
  border-bottom: 1px solid #d4d4d4;
}
.blog-post p,
.blog-post {
  line-height: 1.8 !important;
}
@media screen and (max-width: 991px) {
  :root {
    --slideWidth: 150px;
  }
}
@media screen and (max-width: 767.98px) {
  :root {
    --slideWidth: 100px;
  }
}
.brand-slide {
  /* border: 5px solid #f45545; */
  border-radius: 50%;
  width: 100% !important;
  height: var(--slideWidth);
  background: #fff;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -4px 5px 2px #dfdfdf;
}
.brand-slider .slick-slide {
  padding: 7px 10px;
  width: calc(var(--slideWidth) + 20px);
}
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
  font-size: 24px;
}
.plus-btnround {
  width: 120px !important;
  height: 120px !important;
  margin-top: 71px;
}

@media screen and (max-width: 1199.98px) {
  .slick-next {
    right: -20px;
  }
  .slick-prev {
    left: -20px;
  }
}
.margin-05 {
  margin: 0 0px -5px 10px;
}
.slick-track {
  display: flex;
  justify-content: start;
}

.wrapper-no-record {
  min-width: calc(100vw - 3rem);
  text-align: center;
}
.promo_image {
  min-height: 140px;
  max-height: 140px;
  margin-top: 40px;
}
.footer-bottom .h6 {
  font-size: 14px !important;
}
