body {
  font-family: "Roc Grotesk";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

/* typography css */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
a {
  color: #f45545;
  text-decoration: none;
}
a:hover {
  color: #f45545;
}
p {
  margin-bottom: 0;
}
img {
  max-width: 100%;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.gx-6 {
  --bs-gutter-x: 6rem;
}
/* typography css */

/* form css */
.form-control.black-border {
  border: 1px solid #000000 !important;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 15%);
  border-radius: 0 !important;
  font-size: 18px !important;
  min-height: 70px;
}
.form-control.black-border::placeholder {
  color: #c3c3c3;
}
.form-label {
  font-size: 18px;
  line-height: 22px;
  color: #768ea0;
}
/* form css */

/* text color css */
.primary_text_color {
  color: #768ea0;
}
.bg-bedge-primary {
  background-color: #ffffff !important;
  color: #f45545;
  padding: 0.25em 0.45em;
}
.btn-primary-red.btn .badge {
  margin-left: 5px;
}
.btn-outline-primary.click {
  background-color: #ececec;
  border-color: #ececec !important;
}
.btn-outline-primary {
  color: #f45545 !important;
  border-color: #f45545 !important;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #f45545 !important;
}
.primary_red_color {
  color: #f45545;
}
.color-black {
  color: #000;
}
.font-18 {
  font-size: 18px;
  line-height: 24px;
}
.font-16 {
  font-size: 16px;
  line-height: 22px;
}
.font-14 {
  font-size: 14px;
  line-height: 15px;
}
/* text color css */

/* background color css */
.bg_facebook {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}
.bg_google {
  background-color: #f14436 !important;
  border-color: #f14436 !important;
}
/* background css */

/* width css */
.max-300 {
  max-width: 300px;
}
/* width css */

/* button css */
.btn-primary.btn-primary-red {
  background-color: #f45545;
  border-color: #f45545;
  color: #fff;
  font-size: 16px;
  line-height: 23px;
  min-height: 70px;
  border-radius: 8px;
  text-transform: uppercase;
}
.btn-primary.btn-primary-red:focus {
  outline: none;
  box-shadow: none;
}
.btn-primary.btn-signup {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 45px;
}
.btn-primary.btn-red {
  background: #f45545;
  border-color: #f45545;
  border-radius: 4px;
  text-transform: uppercase;
}
/* button css */

/* btn filter group */
.btn-group.btn-filter-group {
  border: 0;
}
.btn-group label.btn.btn-outline-primary {
  border-radius: 0;
  border-color: #000 !important;
  font-size: 20px;
  line-height: 23px;
  color: #000 !important;
  padding: 15px;
  margin: 0 5px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  width: 55px;
}
.btn-group label.btn.btn-outline-primary {
  background-color: transparent !important;
}
.btn-group input:checked + label.btn.btn-outline-primary {
  /* color: #f45545 !important; */
  background-color: #f45545 !important;
  color: #fff !important;
}
.btn-group.btn-filter-group-check label.btn.btn-outline-primary {
  width: auto !important;
  border: 0;
  padding: 10px 15px;
  box-shadow: none;
  font-size: 13px;
  min-width: 110px;
  line-height: 25px;
  border-radius: 20px;
  max-height: 40px;
  position: relative;
  margin-bottom: 15px;
}
.btn-group.btn-filter-group-check label.btn.btn-outline-primary:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  line-height: 100%;
  min-height: 53px;
  background-image: url(../Images/round.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}
.btn-group.btn-filter-group-check
  input:checked
  + label.btn.btn-outline-primary:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  top: 0px;
  line-height: 100%;
  min-height: 53px;
  background-image: url(../Images/round_fill.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
}
/* .btn-group.btn-filter-group-check label.btn.btn-outline-primary:before {
  content: " ";
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  line-height: 100%;
  min-height: 53px;
  background-image: url(../Images/button_shape.svg);
  background-repeat: no-repeat;
  background-size: 100%;
} */
.btn-group.btn-filter-group-check
  input:checked
  + label.btn.btn-outline-primary {
  /* background: #f45545 !important; */
  color: #fff !important;
}
/* btn filter group */

/* black tab */
.nav-pills.black_tab li button {
  background: #ececec;
  border: 1px solid #000000;
  color: #000;
  border-radius: 0;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 20px;
  min-width: 235px;
}
.nav-pills.black_tab li button.active {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000 !important;
}
/* black tab */

/* bulk deals tab css */
.bulk-deals-sort-btn {
  background: #ececec;
  border: 1px solid #000000;
  color: #000;
  text-transform: uppercase;
}
.bulk-deals-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
