@media screen and (max-width: 1399px) {
  /* .box_inner {
    width: 20%;
  } */
  /* .border_div .box_inner:nth-child(6n) {
    border-right: 1px solid #000;
    border-left: none;
  } */
  /* .border_div .box_inner:nth-child(n) {
    border-right: 1px solid #000;
  }
  .border_div .box_inner:nth-child(11n) {
    border-left: none;
  }
  .border_div .box_inner:nth-child(14n),
  .border_div .box_inner:nth-child(15n) {
    border-bottom: 1px solid #000;
  } */
}

@media screen and (max-width: 1389px) {
  /* .box_inner:nth-child(6n),
  .box_inner:nth-child(11n) {
    border-left: 1px solid #000;
  }
  .border_div .box_inner:nth-child(8n),
  .border_div .box_inner:nth-child(10n),
  .border_div .box_inner:nth-child(15n) {
    border-right: none;
  } */
}

@media screen and (max-width: 991px) {
  .max-ipad-400 {
    max-width: 100%;
    display: block;
  }
  .footer-logo img {
    margin: 0 auto;
  }
  .header_bottom.fixed_header_bottom {
    top: 90px !important;
  }
}

@media screen and (max-width: 1200px) {
  .card.box-shedo-card {
    min-height: 330px;
  }

  .nav-pills.black_tab li button {
    font-size: 17px;
    min-width: 153px;
    padding: 13px;
  }

  .icon_md {
    width: 35px;
  }

  header .form-control.border-input {
    min-height: 50px;
  }

  header .icon_search .search_icon {
    top: 10px;
  }

  /* .box_inner:nth-child(9n),
  .box_inner:nth-child(13n) {
    border-left: 1px solid #000;
  } */

  .grid {
    width: 100%;
  }

  .grid2,
  .grid1 {
    width: 100%;
  }

  .grid2 {
    grid-row: 2;
  }

  .grid1 {
    grid-row: 4;
  }

  .grid2 .card.box-shedo-card .card-body,
  .grid1 .card.box-shedo-card .card-body {
    min-height: inherit;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .grid {
    width: 33.3%;
  }

  .grid2,
  .grid1 {
    width: 33.3%;
  }

  .logo-name-set {
    margin-bottom: 20px;
  }

  .bulk_deals_product_content {
    width: 33.3%;
  }
}

@media screen and (max-width: 1280px) {
  .body_section {
    /* margin-top: 180px !important; */
  }

  .change_font .font-18 {
    font-size: 13px;
  }

  .body_section1 {
    margin-top: 90px !important;
  }

  .carousel-indicators {
    bottom: -60px;
  }

  .right_wrap {
    padding: 60px 40px 30px;
  }

  .verify_image {
    background-size: contain;
  }
}

@media screen and (max-width: 1024px) {
  .header-middle-promo-card {
    padding: 0 10px;
    margin: unset;
    width: 100%;
  }
  .product_name {
    max-width: 410px;
  }
}

@media screen and (max-width: 1024.98px) {
  .header_bottom.header-bottom-active {
    top: 155px;
  }
}

@media screen and (max-width: 991px) {
  .product_name {
    /* max-width: 330px; */
  }

  /* .large_header .icon_search {
    width: 210px;
    max-width: 210px;
  } */

  .filter_button .btn-group label.btn.btn-outline-primary {
    min-width: 125px;
    padding: 10px 14px;
  }

  .body_section {
    /* margin-top: 180px !important; */
  }

  .body_section1 {
    /* margin-top: 60px !important; */
  }

  header .form-control.border-input {
    min-height: 45px;
  }

  .icon_md {
    width: 33px;
  }

  .nav-pills.black_tab li button {
    font-size: 16px;
    min-width: 110px;
    padding: 11px;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .grid {
    width: 50%;
  }

  .grid2,
  .grid1 {
    width: 50%;
  }

  /* .border_div .box_inner:nth-child(3n) {
    border-right: none;
  }
  .border_div .box_inner:nth-child(4n),
  .border_div .box_inner:nth-child(9n),
  .border_div .box_inner:nth-child(10n) {
    border-left: none;
  } */
  /* .box_inner {
    width: 33.3%;
  } */
  .verify_image {
    background-size: contain;
  }

  .verify_image {
    min-height: 350px;
  }

  .max-400 {
    max-width: 400px;
    min-width: 400px;
  }
  .max-full {
    width: 100%;
    max-width: 100%;
  }
  .max-full .form-control {
    max-width: 100%;
  }
  .filter-area-wrap {
    width: 100%;
  }
  .filter-area-wrap .sortbtncheck {
    min-width: 33.33% !important;
  }
}

/* @media screen and (max-width: 575px) {
  .image-gallery-slides {
    max-height: 430px;
  }
} */
@media screen and (max-width: 767px) {
  div.plus-btnround {
    margin-top: 22px !important;
    width: 70px !important;
    height: 70px !important;
  }
  .body_wrap header.large_header + .body_section {
    /* margin-top: 290px !important; */
    padding-top: 20px;
  }

  .body_wrap header.small_header + .body_section {
    /* margin-top: 120px !important; */
    padding-top: 20px;
  }

  .sidebar img {
    max-width: 24px;
  }

  .body_wrap header.header_no_bg + .body_section {
    margin-top: 70px !important;
    padding-top: 20px;
  }

  .responsive_width {
    width: 100%;
    max-width: 100%;
  }

  .order_3 {
    order: 3 !important;
  }

  .w_50 {
    width: 50%;
  }

  .order_4 {
    order: 4 !important;
  }

  .icon-50 {
    width: 30px;
    height: 30px;
    display: inline-block;
  }

  .body_section {
    /* margin-top: 205px !important; */
    padding-top: 20px;
  }

  .right_filter {
    width: 100%;
    padding: 20px 15px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .nav-pills.black_tab li button {
    min-width: inherit;
    min-width: 116px;
    padding: 10px;
    font-size: 14px;
  }

  header .icon_search {
    width: 100%;
    max-width: 100%;
  }

  header .icon_search .form-control.border-input {
    max-width: inherit;
  }

  header .form-control.border-input {
    min-height: 45px;
  }

  header .icon_search .search_icon {
    top: 9px;
  }

  .change_product {
    top: 7px;
  }

  .grid {
    width: 50%;
  }

  .grid2,
  .grid1 {
    width: 50%;
  }

  .icon_md {
    width: 34px;
  }

  .left_menu_side li a {
    font-size: 17px;
  }

  .logo-295 {
    max-width: 160px;
  }

  .header_top h6 {
    font-size: 9px;
    margin-bottom: 0 !important;
  }

  .avatar-64 {
    width: 45px;
    height: 45px;
  }

  .box_inner {
    width: 50%;
  }

  /* .border_div .box_inner:nth-child(3n) {
      border-right: 1px solid #000;
      border-left: none;
    }
    .border_div .box_inner:nth-child(4n),
    .border_div .box_inner:nth-child(6n),
    .border_div .box_inner:nth-child(10n),
    .border_div .box_inner:nth-child(14n) {
      border-right: none;
    }
    .box_inner:nth-child(3n),
    .box_inner:nth-child(10n) {
      border-left: 1px solid #000;
    } */
  .right_wrap {
    padding: 30px 15px 15px;
  }

  .slider_image {
    max-height: 300px;
    min-height: inherit;
  }

  .slider_image img {
    max-height: 325px;
  }

  .form-control.black-border {
    min-height: 50px;
  }

  .btn-primary.btn-primary-red {
    min-height: 50px;
    font-size: 15px;
  }

  .slider_inner p {
    font-size: 14px;
    line-height: 25px;
    min-height: inherit;
    margin-top: 15px;
  }

  .verify_image {
    background-size: contain;
  }

  .verify_image {
    min-height: 350px;
  }

  .font-18 {
    font-size: 14px;
    line-height: 22px;
  }

  .font-14 {
    font-size: 12px;
    line-height: 12px;
  }

  .table_m_scroll {
    height: calc(100vh - 533px);
    overflow: auto;
  }

  .logo_scroll {
    height: calc(100vh - 533px);
    overflow: auto;
    min-width: auto;
  }

  .step_text {
    font-size: 12px;
  }

  .font-m-16 {
    font-size: 15px;
  }

  .image-logo {
    min-width: none;
    min-width: none;
  }

  .max-300 {
    max-width: 200px;
  }
  .slick-next {
    right: 0 !important;
  }
  .slick-prev {
    left: 0 !important;
  }

  .header_bottom.header-bottom-active {
    top: 110px !important;
  }
}

@media screen and (max-width: 485px) {
  .filter_button .btn-group label.btn.btn-outline-primary {
    min-width: 102px;
    padding: 10px 12px;
    font-size: 14px !important;
  }

  .grid2,
  .grid1 {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .header-promo-card-content .header-promo-image-text-content {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .select__control.select__control {
    width: 100%;
    max-width: 100% !important;
  }

  .border_div {
    padding: 0 10px;
  }

  .sku_filter_div {
    width: 100%;
    overflow-x: auto;
  }

  .div_button .btn-group label.btn.btn-outline-primary {
    margin-bottom: 10px !important;
  }

  .card.box-shedo-card {
    min-height: 280px;
  }

  .black_tab .nav-item {
    width: 33.33%;
  }
  .black_tab .nav-item .nav-link {
    width: 100%;
    min-width: auto;
  }
  .filter-listing {
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .box_inner {
    width: 100%;
  }
}

@media screen and (max-height: 720px) {
  .font-18 {
    font-size: 13px;
  }

  .font-20 {
    font-size: 18px;
  }

  .font_h_20 {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }

  .font-14 {
    font-size: 12px !important;
  }

  .font_7_18 {
    font-size: 18px;
  }

  .slider_inner p {
    font-size: 16px;
  }

  .max-w-150 {
    max-width: 150px;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  body .btn-primary.btn-primary-red {
    min-height: inherit !important;
  }

  .btn-primary.btn-primary-red {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .min-290 {
    min-width: 250px;
  }

  .btn-primary.btn-signup {
    padding: 15px 20px;
  }

  .max-150 {
    width: 150px;
    margin-left: 0 !important;
  }

  .max-280 {
    max-width: 280px !important;
    height: 30px !important;
  }
}

@media screen and (min-width: 720px) {
  .fullscreen img.image-gallery-image {
    height: max-content !important;
  }
}
@media screen and (max-width: 1540px) {
  .footer-bottom p,
  .footer-bottom .h6,
  .footer_bottom_links li a {
    font-size: 13px !important;
  }
  .footer_bottom_links li {
    padding: 0 7px;
  }
}
@media screen and (max-width: 1450px) {
  .footer_bottom_links li {
    padding: 0 5px !important;
  }
  .footer-bottom p,
  .footer-bottom .h6,
  .footer_bottom_links li a {
    font-size: 12px !important;
  }

  .footer_bottom_links_wrap {
    padding-left: 0 !important;
  }
  .footer-logo img {
    max-width: 150px !important;
  }
  .footer-bottom .sm-icon img {
    max-width: 70px !important;
  }

  .header-bottom-icons-content .header-bottom-icons-img {
    height: 55px;
    width: 55px;
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .footer-bottom p,
  .footer-bottom .h6,
  .footer_bottom_links li a {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 991px) {
  .product_name {
    max-width: 100%;
  }
  div.plus-btnround {
    width: 100px !important;
    height: 100px !important;
    margin-top: 31px;
  }
}
@media screen and (max-width: 540px) {
  .filter-price-wrap {
    width: 100%;
  }
  .filter-price-wrap .sortbtncheck {
    min-width: 33.33% !important;
  }
  .avatar-64 {
    width: 35px;
    height: 35px;
    border: 4px solid #f45545;
  }
  .header-promo-card-text {
    font-size: 1rem;
  }
  .header-promo-image-text-content img.product_image {
    max-height: 90px;
  }

  .header-promo-image-text-content .header-promo-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 1199.98px) {
  .h5.header-bottom-icons-text {
    font-size: 1.1rem !important;
  }
}
@media screen and (max-width: 991.98px) {
  .logo-name-set {
    margin-bottom: -7px !important;
  }
  .header_bottom.header-bottom-active {
    top: 145px;
  }
}
@media screen and (max-width: 768.98px) {
  .logo-name-set {
    margin-bottom: -7px !important;
  }
  .header_bottom.fixed_header_bottom {
    top: 75px !important;
  }
  .header_bottom.header-bottom-active {
    top: 130px;
  }
  .bulk_deals_product_content .placeholder_image {
    min-height: 100px !important;
  }
  .bulk_deals_product_content img.product_image {
    max-height: 100px !important;
  }
}

@media screen and (max-width: 500px) {
  .header_bottom.fixed_header_bottom {
    top: 60px !important;
  }
}

@media screen and (max-width: 599.98px) {
  .h5.header-bottom-icons-text {
    font-size: 12px !important;
  }
  .bulk-deals-main-content {
    display: grid !important;
    grid-template-columns: auto !important;
  }
}

@media screen and (max-width: 345.98px) {
  .header_bottom.header-bottom-active {
    top: 135px;
  }
}

@media screen and (max-width: 280px) {
  .avatar-64 {
    width: 38px;
    height: 38px;
  }

  .logo-name-set {
    margin-bottom: 0 !important;
  }

  .link-list {
    display: flex;
    flex-direction: column;
  }

  .link-list li a {
    font-size: 11px !important;
  }

  .body_section {
    /* margin-top: 135px !important; */
  }

  .logo-295 {
    max-width: 102px;
  }

  .max-280 {
    max-width: 210px !important;
    height: 23px !important;
  }

  .header_top h6 {
    font-size: 7px;
    margin-bottom: 0 !important;
  }

  .sm-icon a {
    margin-left: 0 !important;
  }

  .wrapper {
    padding: 0;
  }

  .large_header .icon_search {
    width: 100%;
    max-width: 100%;
  }

  .SkuPrice .rc-slider-handle-1:after {
    font-size: 15px;
  }

  .SkuPrice .rc-slider-handle-2:after {
  }

  .filter_button .btn-group label.btn.btn-outline-primary {
    min-width: 66px;
    padding: 10px 14px;
    font-size: 13px !important;
  }

  .font-14 {
    font-size: 10px !important;
  }
}
@media (min-width: 991px) {
  .header-wrap .header_top,
  .header-wrap .header_bottom {
    padding: 0 10px;
  }
}
@media (max-width: 575.98px) {
  .logo-img {
    width: 40px !important;
  }
  .open-btn {
    font-size: 12px;
    padding: 5px 12px;
  }
  .close-white {
    font-size: 18px !important;
    margin-left: -5px;
  }
  .seekrz-alert {
    padding: 10px 10px;
  }
  .seekrz-alert.show ~ .body_wrap .header-wrap {
    top: 62px !important;
  }
  .logo-name-set {
    margin-bottom: 8px;
  }

  .bulk_deals_product_content {
    width: 33%;
  }

  .bulk_deals_product_content .placeholder_image {
    min-height: 100%;
    margin-top: 0;
  }

  .bulk_deals_product_content img.product_image {
    max-height: 50px;
  }

  .bulk_deals_product_content .product_price {
    font-size: 12px;
  }

  .bulk_deals_product_content .product_price::after {
    min-width: 71px;
  }
}
@media (max-width: 520px) {
  .seekrz-alert.show ~ .body_wrap .header-wrap {
    top: 62px !important;
  }
  .font12 {
    font-size: 12px;
  }
  .product-icon {
    font-size: 12px;
  }
  .guaranteed {
    width: 20px;
    height: 20px;
    top: 22px;
  }
  .product-icon {
    margin-left: 10px;
  }
  .fontsize-16 {
    font-size: 16px;
  }
  .header-login {
    margin-top: 0px;
  }
  .width-60 {
    width: 70px;
    margin-left: 40px !important;
  }
  .header-bottom-icons-content {
    margin-right: 0;
  }
  .header-bottom-icons-text {
    font-size: 12px;
  }
}
@media (max-width: 340px) {
  .seekrz-alert.show ~ .body_wrap .header-wrap {
    top: 88px !important;
  }
}
.header-loginlogo {
  width: 100%;
  height: 100%;
}
